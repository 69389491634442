import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hey.
    </Text>
    <p>{`Ich bin Paul, herzlich Willkommen auf meiner persönlichen Webseite. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      